<template>
  <div>
    <ProfileInfo :info="accountInfo" />
    <ProfileInfo :info="personalInfo" />
    <ProfileInfo v-show="toggleGuardian" :info="guardianInfo" />
  </div>
</template>

<script>
import validators from "@/helpers/validationHelper";

export default {
  name: "Profile",
  components: {
    ProfileInfo: () => import("@/components/ProfileInfo"),
  },
  computed: {
    toggleGuardian() {
      const role = this.$store.state.authModule.profile.role;
      if (role == "student") {
        return true;
      }

      return false;
    },
    accountInfo() {
      const { username, email } = this.$store.state.authModule.profile;
      const accountInfo = {
        name: "Account Information",
        type: "account",
        action: this.submitProfile,
        data: [
          {
            label: "Email Address",
            value: email,
          },
          {
            label: "Username",
            value: username,
          },
          {
            label: "Password",
            value: "********",
          },
        ],
        editData: [
          {
            label: "Email Address",
            value: email,
            key: "email",
            onInput: this.updateProfile,
            rules: [validators.required],
          },
          {
            label: "Username",
            value: username,
            key: "username",
            onInput: this.updateProfile,
            rules: [validators.required],
          },
          {
            prefix:
              "If you wish to update your password, fill out the fields below. Otherwise, leave them blank.",
            label: "Current Password",
            value: "",
            key: "currentPassword",
            onInput: this.updateProfile,
            type: "password",
          },
          {
            label: "New Password",
            value: "",
            key: "newPassword",
            onInput: this.updateProfile,
            type: "password",
          },
          {
            label: "Confirm New Password",
            value: "",
            key: "confirmPassword",
            onInput: this.updateProfile,
            type: "password",
          },
        ],
      };

      return accountInfo;
    },
    personalInfo() {
      const { firstName, middleName, lastName, contactNo } =
        this.$store.state.authModule.profile;

      const personalInfo = {
        name: "Personal Information",
        type: "personal",
        action: this.submitProfile,
        data: [
          {
            label: "Full Name",
            value: `${firstName} ${middleName || ""} ${lastName}`,
          },
          {
            label: "Contact Number",
            value: contactNo,
          },
        ],
        editData: [
          {
            label: "First Name",
            value: firstName,
            key: "firstName",
            onInput: this.updateProfile,
            rules: [validators.required],
          },
          {
            label: "Middle Name",
            value: middleName,
            key: "middleName",
            onInput: this.updateProfile,
          },
          {
            label: "Last Name",
            value: lastName,
            key: "lastName",
            onInput: this.updateProfile,
            rules: [validators.required],
          },
          {
            label: "Contact Number",
            value: contactNo,
            key: "contactNo",
            onInput: this.updateProfile,
            rules: [validators.required],
          },
        ],
      };

      return personalInfo;
    },
    guardianInfo() {
      const { guardian, guardianContactNo } =
        this.$store.state.authModule.profile;
      const guardianInfo = {
        name: "Guardian Information",
        type: "guardian",
        action: this.submitProfile,
        data: [
          {
            label: "Full Name",
            value: guardian,
          },
          {
            label: "Contact Number",
            value: guardianContactNo,
          },
        ],
        editData: [
          {
            label: "Full Name",
            value: guardian,
            key: "guardian",
            onInput: this.updateProfile,
          },
          {
            label: "Contact Number",
            value: guardianContactNo,
            key: "guardianContactNo",
            onInput: this.updateProfile,
          },
        ],
      };

      return guardianInfo;
    },
  },
  data() {
    return {
      validators,
    };
  },
  methods: {
    updateProfile(index, value) {
      this.$store.commit("authModule/updateProfile", { index, value });
    },
    async submitProfile(type) {
      return await this.$store.dispatch("authModule/submitProfile", type);
    },
  },
  mounted() {
    this.$store.dispatch("authModule/getProfile");
  },
};
</script>

<style></style>
